<template>
  <v-container>
    <div
      class="d-flex"
    >
      <div :class="[`text-h4`, `mb-5`]"> {{ $route.params.id }} </div>
      <div class="ml-auto mt-2">
        <v-btn
          small
          icon
          @click="isJson = !isJson"
        >
          <v-icon>mdi-code-json</v-icon>
        </v-btn>
      </div>
    </div>
    <div>
      <fiware-entities-form :isJSON="isJson" :entities.sync="entitiesForm" :isCreated="true" ref="entities"></fiware-entities-form>
      <!-- <v-jsoneditor v-if="isJson" v-model="filterEntitiesForm" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor> -->
      <confirm-dialog v-model="isCancel" type="cancle" :title="$route.params.id" @confirm="$router.go(-1)"></confirm-dialog>
    </div>
    <div
      class="d-flex mt-6"
    >
      <v-btn
        class="ml-auto mx-2"
        depressed
        small
        color="success"
        @click="$refs.entities.updateEntities()"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Update
      </v-btn>
      <v-btn
        class="mx2"
        small
        depressed
        color="warning"
        @click="isCancel = true"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancel
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import api from '@/services/api'

var attributesForm = {
  attrName: null,
  type: {
    items: ['Boolean', 'Number', 'Text', 'geo:json', 'geo:point'],
    type: null
  },
  attrValueType: 'single',
  values: {
    singleValue: null,
    objectValue: {
      objType: ['keyValue'],
      keyValue: [
        {
          key: null,
          value: null
        }
      ],
      objValue: [
        {
          objName: null,
          keyValue: [
            {
              key: null,
              value: null
            }
          ]
        }
      ],
      arrayValue: [
        {
          key: null,
          value: [],
          inputArr: null
        }
      ]
    },
    arrayValue: {
      arrtype: ['value'],
      singleValue: {
        values: [],
        inputValue: null
      },
      keyValue: [
        {
          key: null,
          value: null,
          inputValue: null
        }
      ],
      arrValue: {
        arr: [
          {
            values: [],
            inputValue: null
          }
        ]
      }
    }
  },
  metadata: [{
    name: null,
    value: null,
    type: null
  }]
}

Object.freeze(attributesForm)

function isString (value) {
  return typeof value === 'string' || value instanceof String
}
function isNumber (value) {
  return typeof value === 'number' && isFinite(value)
}
function isObject (value) {
  return value && typeof value === 'object' && value.constructor === Object
}
function isArray (value) {
  return value && typeof value === 'object' && value.constructor === Array
}

export default {
  name: 'FiwareEntityDetail',
  components: {
    FiwareEntitiesForm: () => import('@/components/fiware/FiwareEntitiesForm'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  data: () => ({
    isCancel: false,
    isJson: false,
    entitiesForm: {},
    test: {}
  }),
  methods: {
    setData (err, post) {
      if (err) {
        this.error = err.toString()
      } else {
        this.post = post
      }
    },
    fetchEntity: function () {
      api.get(`/f/v2/entities/${this.$route.params.id}`, 'demo', null, this.$store.state.tokenRPT).then((response) => {
        response.json().then((json) => {
          // this.test = json
          this.entitiesForm = this.convertEntitiesToForm(json)
        })
      })
    },
    convertEntitiesToForm: function (entities) {
      let entitiesOb = {
        id: entities.id,
        type: entities.type,
        attributes: []
      }
      Object.keys(entities).filter(entitie => entitie !== 'id' && entitie !== 'type').forEach(attrName => {
        const attrObj = JSON.parse(JSON.stringify(attributesForm))
        attrObj.attrName = attrName
        Object.keys(entities[attrName]).forEach(attrKey => {
          if (attrKey === 'type') {
            attrObj.type.type = entities[attrName][attrKey]
          } else if (attrKey === 'value') {
            if (isObject(entities[attrName][attrKey])) {
              attrObj.attrValueType = 'object'
              // let isKv = false
              let isObj = false
              let isArr = false
              Object.keys(entities[attrName][attrKey]).forEach(attrObjValueKey => {
                if (isString(entities[attrName][attrKey][attrObjValueKey]) || isNumber(entities[attrName][attrKey][attrObjValueKey])) {
                  // if (!isKv) attrObj.values.objectValue.objType.push('keyValue')
                  attrObj.values.objectValue.keyValue.push({ key: attrObjValueKey, value: entities[attrName][attrKey][attrObjValueKey].toString() })
                  // isKv = true
                } else if (isObject(entities[attrName][attrKey][attrObjValueKey])) {
                  let keyVal = []
                  for (const [valObjKey, valObjValue] of Object.entries(entities[attrName][attrKey][attrObjValueKey])) {
                    keyVal.push({ key: valObjKey, value: valObjValue.toString() })
                  }
                  if (!isObj) attrObj.values.objectValue.objType.push('object')
                  attrObj.values.objectValue.objValue.push({ objName: attrObjValueKey, keyValue: keyVal })
                  isObj = true
                } else if (isArray(entities[attrName][attrKey][attrObjValueKey])) {
                  if (!isArr) attrObj.values.objectValue.objType.push('array')
                  attrObj.values.objectValue.arrayValue.push({ key: attrObjValueKey, value: entities[attrName][attrKey][attrObjValueKey], inputArr: null })
                  isArr = true
                }
              })
              attrObj.values.objectValue.keyValue.push(attrObj.values.objectValue.keyValue.shift())
              attrObj.values.objectValue.objValue.push(attrObj.values.objectValue.objValue.shift())
              attrObj.values.objectValue.arrayValue.push(attrObj.values.objectValue.arrayValue.shift())
            } else if (isArray(entities[attrName][attrKey])) {
              attrObj.attrValueType = 'array'
              // let isArrValue = false
              let isArrKv = false
              let isArrArr = false
              entities[attrName][attrKey].forEach(attrArrValueKey => {
                if (isString(attrArrValueKey)) {
                  // if (!isArrValue) attrObj.values.arrayValue.arrtype.push('value')
                  attrObj.values.arrayValue.singleValue.values.push(attrArrValueKey)
                  // isArrValue = true
                } else if (isObject(attrArrValueKey)) {
                  if (!isArrKv) attrObj.values.arrayValue.arrtype.push('keyValue')
                  for (const [key, value] of Object.entries(attrArrValueKey)) {
                    attrObj.values.arrayValue.keyValue.push({ key: key, value: value.toString(), inputValue: null })
                  }
                  isArrKv = true
                } else if (isArray(attrArrValueKey)) {
                  if (!isArrArr) attrObj.values.arrayValue.arrtype.push('array')
                  attrObj.values.arrayValue.arrValue.arr.push({ values: attrArrValueKey, inputValue: null })
                  isArrArr = true
                }
              })
              attrObj.values.arrayValue.singleValue.values.push(attrObj.values.arrayValue.singleValue.values.shift())
              attrObj.values.arrayValue.keyValue.push(attrObj.values.arrayValue.keyValue.shift())
              attrObj.values.arrayValue.arrValue.arr.push(attrObj.values.arrayValue.arrValue.arr.shift())
            } else {
              attrObj.attrValueType = 'single'
              attrObj.values.singleValue = entities[attrName][attrKey]
            }
          } else if (attrKey === 'metadata') {
            Object.keys(entities[attrName][attrKey]).forEach(metaKey => {
              // console.log(metaKey)
              let metaObj = {
                name: null,
                value: null,
                type: null
              }
              metaObj.name = metaKey
              Object.keys(entities[attrName][attrKey][metaKey]).forEach(metaNameKey => {
                if (metaNameKey === 'type') {
                  metaObj.type = entities[attrName][attrKey][metaKey][metaNameKey]
                }
                if (metaNameKey === 'value') {
                  metaObj.value = entities[attrName][attrKey][metaKey][metaNameKey]
                }
              })
              attrObj.metadata.push(metaObj)
            })
            attrObj.metadata.push(attrObj.metadata.shift())
          }
        })
        entitiesOb.attributes.push(attrObj)
      })
      return entitiesOb
    },
    jsoneditorError: function (error) {
      console.log(error)
    }
  },
  computed: {
  },
  watch: {
    '$store.state.fiwareService': function () {
      // this.$router.back()
      this.$router.go(-1)
    }
  },
  created () {
    this.fetchEntity()
  }
}
</script>
